import React, { Component } from "react";
import { Link } from "react-router-dom";
import './HomePage.css';

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

const fakeDate = new Date().getTime() + getRandomArbitrary(30000000, 70000000);

class HomePage extends Component {
    constructor() {
        super();
        this.state = { countDownDate: new Date("Jan 5, 2022 15:37:25").getTime(), countdown: '0', size: '', sort: '', cartItems: [], products: [], filteredProducts: [] , infoProductActive: false, productSelected: {}, productSelectedTypeIndex: 0};
    }

    componentWillMount() {

        // var details = {
        //     "email": "gustavo.sales.gman@gmail.com",
        //     "token": "8b8ff9de-c849-4db6-8f4c-7b08173d33db026b6d8f4a5c8f35bd5dec9069a47cf8351c-2358-420e-82a7-ad98f8c3a344",
        //     "currency": "BRL",
        //     "itemId1": "0001",
        //     "itemDescription1": "Notebook Prata",
        //     "itemAmount1": 100.00,
        //     "itemQuantity1": 1,
        //     "itemId2": "0002",
        //     "itemDescription2": "Nelson",
        //     "itemAmount2": 50.00,
        //     "itemQuantity2": 3,
        //     "shippingType": 1,
        //     "redirectURL": "http://sitedocliente.com",
        //     "shippingCost": 0.00,
        // };

        // var formBody = [];
        // for (var property in details) {
        //     var encodedKey = encodeURIComponent(property);
        //     var encodedValue = encodeURIComponent(details[property]);
        //     formBody.push(encodedKey + "=" + encodedValue);
        // }
        // formBody = formBody.join("&");


        // console.log('Fetch', formBody);

        // fetch('https://ws.pagseguro.uol.com.br/v2/checkout', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        //     },
        //     body: formBody
        // }).then((data) => {
        //     console.log('dado', data);
        // }).catch((error) => {
        //     console.log('erro', error);
        // });;

        if (localStorage.getItem('cartItems')) {
            this.setState({ cartItems: JSON.parse(localStorage.getItem('cartItems')) });
        }

        fetch('db.json').then(res => res.json()).then(data => data.products)
            .then(data => {
                this.setState({ products: data });
            });


        // Update the count down every 1 second
        setInterval(this.iniciarContador, 1000);
    }

    iniciarContador = () => {

        // Get today's date and time
        var now = new Date().getTime();
        
        // Find the distance between now and the count down date
        var distance = (fakeDate) - now;
        
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
        // Output the result in an element with id="demo"
        this.setState({ countdown: days + "d " + hours + "h "
        + minutes + "m " + seconds + "s " });;
        
        // If the count down is over, write some text 
        if (distance < 0) {
        // clearInterval(x);
        // document.getElementById("demo").innerHTML = "EXPIRED";
        }
    }

    render() {
        return (
            <div class="home">
                <div className="titulo">
                    <h1>Salles Buffet</h1>
                    <h5>Orçamento de natal/ano novo personalizado</h5>
                    <img class="chapeu" src="./chapeu.png" />
                </div>

                <div className="titulo">
                    <h4>Ceias sujeito a lotação</h4>
                    <h5>{this.state.countdown}</h5>
                </div>



                <Link className="link" to="list-products">
                    <div className="opcoes-orcamento">
                        <h3>Monte seu orçamento online Personalizado em 3 minutos <span className="link">aqui</span></h3>
                    </div>
                </Link>

                <h3 className="ou-entao">
                    Ou então
                </h3>

                <a href='https://api.whatsapp.com/send?phone=5511995405302&text=Ola, gostaria de solicitar um orçamento de natal/ano novo&source=&data=' target="_blank">
                    <div className="opcoes-orcamento">
                        <h3>Montar orçamento com especialista via Whatsapp <img src="./whatsapp.png" /> </h3>
                    </div>
                </a>
            </div>
        )
    }
}

export default HomePage;