import React, { Component} from "react";
import Products from './Products';
import ProductInfo from './ProductInfo';
import Filter from './Filter';
import Basket from './Basket';
import BasketMobile from './BasketMobile';
import './ListProducts.css';



class ListProducts extends Component {
    constructor(props) {
        super();
        this.state = { size: '', sort: '', cartItems: [], products: [], filteredProducts: [] , infoProductActive: false, productSelected: {}, productSelectedTypeIndex: 0};
        this.selectProductInfo = this.selectProductInfo.bind(this);
        this.changeProductSelectedTypeIndex = this.changeProductSelectedTypeIndex.bind(this);
        this.toggleActive = this.toggleActive.bind(this);
    }

    componentWillMount() {

        if (localStorage.getItem('cartItems')) {
          this.setState({ cartItems: JSON.parse(localStorage.getItem('cartItems')) });
        }
    
        fetch('db.json').then(res => res.json()).then(data => data.products)
          .then(data => {
            this.setState({ products: data });
            this.listProducts();
          });
      }
    
      handleRemoveFromCart = (e, product) => {
        this.setState(state => {
          const cartItems = state.cartItems.filter(a => a.id !== product.id);
          localStorage.setItem('cartItems', JSON.stringify(cartItems));
          return { cartItems: cartItems };
        })
      }
    
      handleAddToCart = (e, product) => {
        this.setState(state => {
          const cartItems = state.cartItems;
          let productAlreadyInCart = false;


          console.log('Q fita é essa', state);
    
          cartItems.forEach(cp => {
            if (cp.id === product.id) {
              cp.count += 1;
              productAlreadyInCart = true;
            }
          });
    
          if (!productAlreadyInCart) {
            cartItems.push({ ...product, count: 1 });
          }
          localStorage.setItem('cartItems', JSON.stringify(cartItems));
          return { cartItems: cartItems };
        });
      }
    
      listProducts = () => {
        this.setState(state => {
          if (state.sort !== '') {
            state.products.sort((a, b) =>
              (state.sort === 'lowestprice'
                ? ((a.price > b.price) ? 1 : -1)
                : ((a.price < b.price) ? 1 : -1)));
          } else {
            state.products.sort((a, b) => (a.id > b.id) ? 1 : -1);
          }
          if (state.size !== '') {
            return { filteredProducts: state.products.filter(a => a.availableSizes.indexOf(state.size.toUpperCase()) >= 0) };
          }
          return { filteredProducts: state.products };
        })
      }
      handleSortChange = (e) => {
        this.setState({ sort: e.target.value });
        this.listProducts();
      }
      handleSizeChange = (e) => {
        this.setState({ size: e.target.value });
        this.listProducts();
      }

      selectProductInfo(product) {
        this.setState({
            productSelected: product,
            infoProductActive: true,
            productSelectedTypeIndex: 0,
        });
      }

      changeProductSelectedTypeIndex(index) {
        this.setState({
            productSelectedTypeIndex: index,
        });
      }

      toggleActive() {
        this.setState({
            infoProductActive: !this.state.infoProductActive,
        });
      }



    render() {
        return (
        <div class="lista-produtos">
            <BasketMobile  buttonFinalizar={true} cartItems={this.state.cartItems} handleRemoveFromCart={this.handleRemoveFromCart} />
            <ProductInfo active={this.state.infoProductActive} product={this.state.productSelected} index={this.state.productSelectedTypeIndex} changeIndex={this.changeProductSelectedTypeIndex} fecharProductInfo={this.toggleActive} handleAddToCart={this.handleAddToCart} />
            <div className="titulo">
                    <h1>Salles Buffet</h1>
                    <h5>Orçamento de natal/ano novo personalizado</h5>
                    <img class="chapeu" src="./chapeu.png" />
                </div>
            <div className="row">
              <div className="col-md-12">
                  <Basket buttonFinalizar={true} cartItems={this.state.cartItems} handleRemoveFromCart={this.handleRemoveFromCart} />
              </div>
            </div>

            <div class="row">

            <div className="col-md-12">
                <Filter class="filter" count={this.state.filteredProducts.length} handleSortChange={this.handleSortChange}
                handleSizeChange={this.handleSizeChange} />
                <Products products={this.state.filteredProducts} handleAddToCart={this.handleAddToCart} selectProductInfo={this.selectProductInfo} />
            </div>
            </div>
        </div>
        );
    }
}

export default ListProducts;